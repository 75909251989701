import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgxMaskDirective } from "ngx-mask";

@Component({
  selector: "app-text-input",
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatIconModule, NgxMaskDirective],
  templateUrl: "./text-input.component.html",
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ],
  styles: [`
    :host {
      position: relative;
    }
    :host .search-icon {
      position: absolute;
      right: 2.7rem;
      top: 2.35rem;
    }`
  ]
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() isSearch: boolean;
  @Input() isCleaneable: boolean;
  @Input() disabled: boolean;
  @Input() type = "text";
  @Input() step: number;
  @Input() formControl: UntypedFormControl;
  @Input() pattern: string;
  @Input() phone: boolean = false;
  @Input() name: string;

  @Input() mask: string;
  @Input() dropSpecialCharacters: boolean = true;
  inputValue: any;

  @Output() focused: EventEmitter<null>;
  @Output() blured: EventEmitter<null>;

  constructor(
    private _change: ChangeDetectorRef
  ) {
    this.placeholder = '';
    this.focused = new EventEmitter<null>();
    this.blured = new EventEmitter<null>();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val) {
    if (this.type === "number") {
      val = parseFloat(val);
    }
    this.inputValue = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value() {
    return this.inputValue;
  }

  writeValue(value: any): void {
    this.value = (value) ? value : '';
    this._change.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  cleanValue(): void {
    this.value = '';
    this.formControl?.setValue('');
  }

  emitFocus(): void {
    this.focused.emit(null);
  }

  emitBlur(): void {
    this.blured.emit(null);
  }
}
