<label [ngClass]="{ 'opacity-50': disabled || formControl?.disabled }" class="relative base-input-label w-full">
  <span>
    {{ label }}
    @if (required) {
      <span>*</span>
    }
  </span>
  
  @if (!formControl) {
    <input
      class="base-text-input"
      [type]="type"
      [step]="step"
      [email]="type === 'email'"
      [required]="required"
      [minlength]="minLength || 0"
      [maxlength]="maxLength || 300"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [(disabled)]="disabled"
      [name]="name + '-input'"
      [ngClass]="{'background-grey': disabled }"
      [pattern]="pattern"
      (focus)="emitFocus()"
      (blur)="emitBlur()"/>
  } @else {
    @if (!phone && (!mask || !formControl?.disabled)) {
      <input
        class="base-text-input"
        [type]="type"
        [step]="step"
        [email]="type === 'email'"
        [required]="required"
        [mask]="mask"
        [dropSpecialCharacters]="dropSpecialCharacters"
        [name]="name + '-input'"
        [placeholder]="placeholder"
        [formControl]="formControl"
        [ngClass]="{ 'background-grey': disabled || formControl.disabled }"
        (focus)="emitFocus()"
        (blur)="emitBlur()"
      />
    }
    @if (phone || (mask && formControl?.disabled)) {
      <input
        class="base-text-input"
        [type]="type"
        [step]="step"
        [email]="type === 'email'"
        [required]="required"
        [name]="name + '-input'"
        [placeholder]="placeholder"
        [formControl]="formControl"
        [ngClass]="{ 'background-grey': disabled || formControl.disabled }"
        [hiddenInput]="true"
        [mask]="mask"
        [dropSpecialCharacters]="dropSpecialCharacters"
        (focus)="emitFocus()"
        (blur)="emitBlur()"
      />
    }
  }

  @if (formControl) {
    <ng-container></ng-container>  
  }

  @if (isSearch) {
    <mat-icon  class="md-24 text-gray-light search-icon">
      search
    </mat-icon>
  }

  @if (isCleaneable && (inputValue || formControl.value)) {
    <span (click)="cleanValue()"
          class="absolute top-2 right-0 mr-3 py-1 cursor-pointer">
      x
    </span>
  }
</label>
